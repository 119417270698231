<template>
  <section class="item">
    <div class="timer" :class="{ 'duration-timer': !paused, 'duration-timer-closed': paused }" >
      <span v-if="canEdit" @click="handlePaused">
        <Start class="start-icon" v-if="paused" />
        <Pause class="start-icon" v-else />
      </span>
      <time>
        {{ clock }}
      </time> 
      <Clock class="clock"/>
    </div>
  </section>
</template>
<script>
import moment from 'moment';

export default {
  components: {
    Start: () => import('@/assets/icons/start.svg'),
    Pause: () => import('@/assets/icons/pause.svg'),
    Clock: () => import('@/assets/icons/clock.svg'),
  },
  props: {
    time: { type: String, default: '00:00:00' },
    canEdit: { type: Boolean, default: false },
    startClock: { type: Boolean, default: false },
  },
  data() {
    return {
      timer: null,
      clock: '00:00:00',
      paused: true,
    }
  },
  created () {
    this.handleSetTime()
    this.handleTimer()

    this.paused = !this.startClock
  },
  methods: {
    moment,
    handleSetTime () {
      if (this.time)
        this.setTimer(this.time)
      else 
        this.setDefaultTimer()
    },
    setTimer (time) {
      this.timer = this.moment(time).utcOffset(-3)
      this.timer.toISOString()
      this.timer.format()
      this.clock = this.timer.format('HH:mm:ss')

    },
    setDefaultTimer () {
      this.timer = this.moment().utcOffset(0)
      this.timer.set({hour:0,minute:0,second:0,millisecond:0})
      this.timer.toISOString()
      this.timer.format()

    },
    addSecond () {
      if (this.paused) return

      this.timer = this.timer.add(1, 'seconds');
      this.clock = this.timer.format('HH:mm:ss')
    },
    handlePaused () {
      this.paused = !this.paused
    },
    handleTimer () {
      setInterval(this.addSecond, 1000);
    }
  },

  watch: {
    paused() {
      this.$emit('paused', this.paused)
      if (this.paused)
        this.$emit('timer', this.timer)
    },
    time () {
      this.handleSetTime()
    },
    startClock () {
      this.paused = !this.startClock
    }
  }
}
</script>
<style lang="scss" scoped>
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .start-icon {
      transform: scale(0.9);
      margin-bottom: 2px;
      cursor: pointer;
    }

    .clock {
      width: 16px;
      height: 16px;
    }

    .timer {
      padding: 5px 13px;
      border-radius: 100px;
      font-weight: 700;
      font-size: 14px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
    }
    .duration-timer {
      background-color: var(--states-success-soft-green);
      color: var(--type-active);
      .clock { stroke: var(--states-success); }
    }
    .duration-timer-closed {
      background-color: #d9dff2;
      color: #505050;
      .clock { stroke: #505050; }
    }
  }
</style>
